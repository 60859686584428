/* You can add global styles to this file, and also import other style files */
@use "@angular/material/prebuilt-themes/indigo-pink.css";
@use "mapbox-gl/dist/mapbox-gl.css";
@use "swagger-ui/dist/swagger-ui.css";

/* * * * * * * * * * * * * * * * * * *
 * RESET BROWSER'S DEFAULT STYLES
 * * * * * * * * * * * * * * * * * * */

body, div, p, ul, ol, li, dl, dt, dd, h1, h2, h3, h4, h5, h6,
table, th, td, img, form {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

img, video {
  border: 0;
  vertical-align: bottom;
}

/* * * * * * * * * * * * * * * * * * *
 * GLOBAL STYLES
 * * * * * * * * * * * * * * * * * * */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error-color {
  color: #B00020;
}

.spacer {
  flex: 1 1 auto;
}

.common-table {
  padding: 10px 20px;
}

.common-dialog {
  padding: 10px;
}

.common-card {
  padding: 16px;
}

.common-timepicker {
  width: 190px;
  background-color: #F5F5F5;
  padding: 15px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid;
}

$single-space: 8px;
$double-space: 16px;
$triple-space: 24px;
$half-space: 4px;
$quoter-space: 2px;
